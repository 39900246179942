// Font weights
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-bold: 500;
$font-weight-bolder: 700;

// Base Font
$base-font-family: "Helvetica", "Arial", sans-serif;
$base-font-family-icon: "Helvetica", "Arial", sans-serif, "Material Icons";
$base-font-size: 100%;

// Icon Font
$simple-line-font-path: "../../fonts/";

// Font Sizes
$h1-font-size: 2.25rem;
$h1-font-size-tablet: $h1-font-size - .375;
$h1-font-size-phone: $h1-font-size-tablet - .375;

$h2-font-size: 1.75rem;
$h2-font-size-tablet: $h2-font-size - .125;
$h2-font-size-phone: $h2-font-size-tablet - .25;

$h3-font-size: 1.5rem;
$h3-font-size-tablet: $h3-font-size - .125;
$h3-font-size-phone: $h3-font-size-tablet - .125;

$h4-font-size: 1.125rem;
$h4-font-size-tablet: $h4-font-size - .125;
$h4-font-size-phone: $h4-font-size - .125;

$medium-font-size: 1.375rem;
$medium-font-size-tablet: $medium-font-size - .125;
$medium-font-size-phone: $medium-font-size-tablet - .25;

@import 'modules/all';
@import 'partials/all';
