%covered-background {
  background-attachment: scroll;
  background-color: transparent;
  background-position: center top;
  background-repeat: no-repeat;
  -o-background-size: cover;
  background-size: cover;
}

%std-icon-size {
  -o-background-size: 80px 80px;
  background-size: 80px 80px;
  height: 80px;
  width: 80px;
}

.image,
.image-big {
  width: 100%;
}

.image-big {
  max-height: 200px;
}

.img-center {
  margin: 0 auto 1.5rem;
}

.img-left {
  float: left;
  margin-right: 1rem;
}

.img-link {
  border: 1px solid $bg-gray;
  -webkit-box-shadow: 1px 2px 4px rgba(0, 0, 0, .5);
  box-shadow: 1px 2px 4px rgba(0, 0, 0, .5);
}

.img-border {
  border: 1px solid $bg-gray;
}

.submenu-icon {
  font-size: 1rem;
  font-weight: bold;
}

.app-logo-text {
  background-image: url('/assets/images/app-logo-text.png');
  -o-background-size: 450px 150px;
  background-size: 450px 150px;
  height: 150px;
  width: 450px;
}

@media screen and (max-width: 1024px) {

  .app-logo-text {
    -o-background-size: 455px 120px;
    background-size: 455px 120px;
    height: 120px;
    width: 455px;
  }

  .icons-user {
    -o-background-size: 60px 60px;
    background-size: 60px 60px;
    height: 60px;
    width: 60px;
  }
}

.icon-color-error {
  color: $btn-error-bg-color;
}

.icon-color-success {
  color: $btn-done-bg-color;
}

.icon-color-warning {
  color: $btn-warning-bg-color;
}

.icon-color-info {
  color: $table-th-color;
}

.material-icons {
  &.md-14 {
    font-size: .875rem;
    vertical-align: baseline;
  }

  &.md-16 {
    font-size: 1rem;
    vertical-align: middle;
  }

  &.md-20 {
    font-size: 1.25rem;
    vertical-align: middle;
  }
}

@media screen and (max-width: 640px) {

  .app-logo-text {
    -o-background-size: 228px 60px;
    background-size: 228px 60px;
    height: 60px;
    width: 228px;
  }
}
