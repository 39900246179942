.col,
.col-left {
  -o-background-clip: padding-box !important;
  background-clip: padding-box !important;
  border: 0 solid transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  float: left; }

.row, .row-fixed, .login-headline, .row-fixed-blue, .teaser,
.pageinntertext, .footer, .navigation {
  /*.col {
    padding-left: 1rem;
  }*/ }
  .row .col:first-child, .row-fixed .col:first-child, .login-headline .col:first-child, .row-fixed-blue .col:first-child, .teaser .col:first-child,
  .pageinntertext .col:first-child, .footer .col:first-child, .navigation .col:first-child {
    padding-left: 0; }
  .row .span1, .row-fixed .span1, .login-headline .span1, .row-fixed-blue .span1, .teaser .span1,
  .pageinntertext .span1, .footer .span1, .navigation .span1 {
    width: 16.6666666667%; }
  .row .span2, .row-fixed .span2, .login-headline .span2, .row-fixed-blue .span2, .teaser .span2,
  .pageinntertext .span2, .footer .span2, .navigation .span2 {
    width: 33.3333333333%; }
  .row .span3, .row-fixed .span3, .login-headline .span3, .row-fixed-blue .span3, .teaser .span3,
  .pageinntertext .span3, .footer .span3, .navigation .span3, .row .mobile-span3, .row-fixed .mobile-span3, .login-headline .mobile-span3, .row-fixed-blue .mobile-span3, .teaser .mobile-span3,
  .pageinntertext .mobile-span3, .footer .mobile-span3, .navigation .mobile-span3 {
    width: 50%; }
  .row .span4, .row-fixed .span4, .login-headline .span4, .row-fixed-blue .span4, .teaser .span4,
  .pageinntertext .span4, .footer .span4, .navigation .span4 {
    width: 66.6666666667%; }
  .row .span5, .row-fixed .span5, .login-headline .span5, .row-fixed-blue .span5, .teaser .span5,
  .pageinntertext .span5, .footer .span5, .navigation .span5 {
    width: 83.3333333333%; }
  .row .span6, .row-fixed .span6, .login-headline .span6, .row-fixed-blue .span6, .teaser .span6,
  .pageinntertext .span6, .footer .span6, .navigation .span6 {
    margin-left: 0;
    width: 100%; }
  .row .span7, .row-fixed .span7, .login-headline .span7, .row-fixed-blue .span7, .teaser .span7,
  .pageinntertext .span7, .footer .span7, .navigation .span7 {
    width: 25%; }
  .row .span8, .row-fixed .span8, .login-headline .span8, .row-fixed-blue .span8, .teaser .span8,
  .pageinntertext .span8, .footer .span8, .navigation .span8 {
    width: 75%; }

.row-fixed,
.login-headline,
.row-fixed-blue,
.teaser,
.pageinntertext,
.footer,
.navigation {
  *zoom: 1; }
  .row-fixed:before, .row-fixed:after,
  .login-headline:before,
  .row-fixed-blue:before,
  .teaser:before,
  .pageinntertext:before,
  .footer:before,
  .navigation:before,
  .login-headline:after,
  .row-fixed-blue:after,
  .teaser:after,
  .pageinntertext:after,
  .footer:after,
  .navigation:after {
    content: '';
    display: table; }
  .row-fixed:after,
  .login-headline:after,
  .row-fixed-blue:after,
  .teaser:after,
  .pageinntertext:after,
  .footer:after,
  .navigation:after {
    clear: both; }

@media screen and (max-width: 640px) {
  .mobile-none {
    display: none; }
  .row .span1, .row-fixed .span1, .login-headline .span1, .row-fixed-blue .span1, .teaser .span1,
  .pageinntertext .span1, .footer .span1, .navigation .span1,
  .row .span2, .row-fixed .span2, .login-headline .span2, .row-fixed-blue .span2, .teaser .span2,
  .pageinntertext .span2, .footer .span2, .navigation .span2,
  .row .span3, .row-fixed .span3, .login-headline .span3, .row-fixed-blue .span3, .teaser .span3,
  .pageinntertext .span3, .footer .span3, .navigation .span3,
  .row .span4, .row-fixed .span4, .login-headline .span4, .row-fixed-blue .span4, .teaser .span4,
  .pageinntertext .span4, .footer .span4, .navigation .span4,
  .row .span5, .row-fixed .span5, .login-headline .span5, .row-fixed-blue .span5, .teaser .span5,
  .pageinntertext .span5, .footer .span5, .navigation .span5,
  .row .span6, .row-fixed .span6, .login-headline .span6, .row-fixed-blue .span6, .teaser .span6,
  .pageinntertext .span6, .footer .span6, .navigation .span6,
  .row .span7, .row-fixed .span7, .login-headline .span7, .row-fixed-blue .span7, .teaser .span7,
  .pageinntertext .span7, .footer .span7, .navigation .span7,
  .row .span8, .row-fixed .span8, .login-headline .span8, .row-fixed-blue .span8, .teaser .span8,
  .pageinntertext .span8, .footer .span8, .navigation .span8 {
    padding-left: 0;
    width: 100%; } }

html,
body {
  background-color: #404040;
  border: 0;
  color: #333;
  font-family: "Helvetica", "Arial", sans-serif;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline; }

body {
  -webkit-font-feature-settings: 'liga';
  -moz-font-feature-settings: 'liga';
  font-feature-settings: 'liga';
  text-rendering: optimizeLegibility; }

.wrapper,
.navigation {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 24px;
  padding: 0 1.5rem; }

.wrapper-inner,
.login-headline,
.footer-content {
  margin: 0 auto;
  max-width: 750px;
  padding: 0 24px;
  padding: 0 1.5rem; }

.main {
  background-color: #fff;
  min-height: 600px;
  padding-bottom: 32px;
  padding-bottom: 2rem;
  padding-top: 32px;
  padding-top: 2rem; }

img {
  max-width: 100%; }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.page-headline {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 32px;
  line-height: 2rem; }

.login-headline {
  color: #fff;
  margin: 96px auto 160px;
  margin: 6rem auto 10rem;
  text-align: center; }
  .login-headline p {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 32px;
    line-height: 2rem;
    margin-top: 32px;
    margin-top: 2rem; }
  .login-headline .button {
    margin-top: 44px;
    margin-top: 2.75rem; }

h1,
h2,
h3,
h4,
h5,
h6,
a {
  border-width: 0;
  margin: 0;
  padding: 0;
  -webkit-transform-origin: left top 0;
  -ms-transform-origin: left top 0;
  -o-transform-origin: left top 0;
  transform-origin: left top 0; }

h1 {
  font-size: 36px;
  font-size: 2.25rem;
  font-weight: 500;
  line-height: 44px;
  line-height: 2.75rem; }

h2 {
  font-size: 28px;
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 36px;
  line-height: 2.25rem; }

h3 {
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 32px;
  line-height: 2rem; }

h4 {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 26px;
  line-height: 1.625rem; }

.mt05 {
  margin-top: 8px;
  margin-top: .5rem; }

.mb05 {
  margin-bottom: 8px;
  margin-bottom: .5rem; }

.mt1 {
  margin-top: 16px;
  margin-top: 1rem; }

.mt2 {
  margin-top: 32px;
  margin-top: 2rem; }

.mt3 {
  margin-top: 48px;
  margin-top: 3rem; }

.mt4 {
  margin-top: 64px;
  margin-top: 4rem; }

.mb1 {
  margin-bottom: 16px;
  margin-bottom: 1rem; }

.mb2 {
  margin-bottom: 32px;
  margin-bottom: 2rem; }

.mb3 {
  margin-bottom: 48px;
  margin-bottom: 3rem; }

.mb4 {
  margin-bottom: 64px;
  margin-bottom: 4rem; }

.my1 {
  margin-bottom: 16px;
  margin-bottom: 1rem;
  margin-top: 16px;
  margin-top: 1rem; }

.my2 {
  margin-bottom: 32px;
  margin-bottom: 2rem;
  margin-top: 32px;
  margin-top: 2rem; }

.my3 {
  margin-bottom: 48px;
  margin-bottom: 3rem;
  margin-top: 48px;
  margin-top: 3rem; }

.my4 {
  margin-bottom: 64px;
  margin-bottom: 4rem;
  margin-top: 64px;
  margin-top: 4rem; }

.mr1 {
  margin-right: 16px;
  margin-right: 1rem; }

.mr2 {
  margin-right: 32px;
  margin-right: 2rem; }

.pr05 {
  padding-right: 8px;
  padding-right: 0.5rem; }

.pr1 {
  padding-right: 16px;
  padding-right: 1rem; }

.pl0 {
  padding-left: 0; }

.pl05 {
  padding-left: 8px;
  padding-left: 0.5rem; }

.pl1 {
  padding-left: 16px;
  padding-left: 1rem; }

.pt05 {
  padding-top: 8px;
  padding-top: 0.5rem; }

.pt1 {
  padding-top: 16px;
  padding-top: 1rem; }

.pb05 {
  padding-bottom: 8px;
  padding-bottom: 0.5rem; }

.pb1 {
  padding-bottom: 16px;
  padding-bottom: 1rem; }

.py1 {
  padding-bottom: 16px;
  padding-bottom: 1rem;
  padding-top: 16px;
  padding-top: 1rem; }

.pl1-tablet {
  padding-left: 16px;
  padding-left: 1rem; }

.lh2 {
  line-height: 32px;
  line-height: 2rem; }

.center {
  margin-left: auto;
  margin-right: auto; }

.left {
  float: left; }

.right {
  float: right; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.text-left {
  text-align: left; }

.font-big {
  font-size: 36px;
  font-size: 2.25rem;
  font-weight: 400;
  line-height: 44px;
  line-height: 2.75rem; }

.font-large {
  font-size: 28px;
  font-size: 1.75rem;
  font-weight: 400;
  line-height: 36px;
  line-height: 2.25rem; }

.font-medium {
  font-size: 22px;
  font-size: 1.375rem;
  font-weight: 300;
  line-height: 30px;
  line-height: 1.875rem; }

.font-small {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 300;
  line-height: 20px;
  line-height: 1.25rem; }

.font-very-small {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 18px;
  line-height: 1.125rem; }

aside.sidemenu {
  float: left;
  width: 20%; }

aside hr {
  border: 0;
  border-top: 1px solid #d0d0d0;
  display: block;
  height: 1px;
  margin: 1em 0;
  padding: 0; }

aside ul {
  list-style: none;
  margin: 0;
  padding: 0; }

aside h4 {
  border-radius: 2px;
  color: #999999;
  padding: 0 16px;
  padding: 0 1rem; }

aside a {
  border-radius: 2px;
  display: block;
  padding: 8px 16px;
  padding: .5rem 1rem;
  -o-transition: background-color .25s ease 0s;
  -webkit-transition: background-color .25s ease 0s;
  transition: background-color .25s ease 0s; }
  aside a:hover {
    background-color: #f2f2f2;
    opacity: 1; }
  aside a.warning:hover {
    background-color: #fcf8e3;
    opacity: 1; }

.sidecontent {
  float: left;
  padding-left: 16px;
  padding-left: 1rem;
  width: 80%; }

.row-fixed-blue {
  background-color: #006fba;
  color: #fff; }

.teaser {
  font-style: italic;
  text-align: left; }

.light {
  font-weight: 300; }

.regular {
  font-weight: 400; }

.bold5 {
  font-weight: 500; }

.bold {
  font-weight: 700; }

.text-info {
  font-weight: 300;
  opacity: .8; }

.text-icon {
  font-size: 8px;
  font-size: .5rem;
  font-weight: 300; }

.italic {
  font-style: italic; }

.font-normal {
  font-style: normal; }

.infotext-medium {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 22px;
  line-height: 1.375rem; }

.infotext {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 22px;
  line-height: 1.375rem; }

.cursor-default {
  cursor: default; }

.nowrap {
  white-space: nowrap; }

a {
  color: #333;
  text-decoration: none; }
  a.text {
    font-weight: 500; }
  a:hover {
    opacity: .6; }

ol {
  padding-left: 24px;
  padding-left: 1.5rem; }
  ol li {
    padding-top: 8px;
    padding-top: .5rem; }
    ol li::before {
      font-weight: 500; }

ul {
  list-style: none; }
  ul.list-style {
    list-style: disc; }
  ul.filter {
    display: inline-block; }
    ul.filter li {
      border-bottom: 1px solid #545454;
      border-left: 1px solid #545454;
      border-top: 1px solid #545454;
      cursor: pointer;
      float: left;
      padding: 4px 16px;
      padding: .25rem 1rem; }
      ul.filter li.active, ul.filter li:hover {
        background-color: #b2b2b2;
        color: #fff; }
      ul.filter li:first-child {
        border-bottom-right-radius: 2px;
        border-top-left-radius: 2px; }
      ul.filter li:last-child {
        border-bottom-right-radius: 2px;
        border-right: 1px solid #545454;
        border-top-right-radius: 2px; }

.width100 {
  width: 100%; }

.width50 {
  width: 50%; }

.minus-style {
  list-style-type: '- ';
  margin-left: 0;
  padding-left: 16px;
  padding-left: 1rem; }

.errorbox {
  background-color: #f2dede;
  color: #ba0012;
  padding: 16px;
  padding: 1rem; }

.successbox {
  background-color: #dff0d8;
  color: #325300;
  padding: 16px;
  padding: 1rem; }

.warningbox {
  background-color: #fcf8e3;
  color: #534b00;
  padding: 16px;
  padding: 1rem; }

.infobox {
  background-color: #eee;
  font-size: 22px;
  font-size: 1.375rem;
  font-style: italic;
  font-weight: 300;
  line-height: 30px;
  line-height: 1.875rem;
  padding: 32px;
  padding: 2rem; }

.infobox-small {
  background-color: #eee;
  font-size: 22px;
  font-size: 1.375rem;
  font-weight: 300;
  line-height: 30px;
  line-height: 1.875rem;
  padding: 16px;
  padding: 1rem; }

.infobox-verysmall,
.successbox-verysmall {
  font-size: 14px;
  font-size: .875rem;
  font-weight: 300;
  line-height: 18px;
  line-height: 1.125rem;
  padding: 4px 16px;
  padding: .25rem 1rem; }

.infobox-verysmall {
  background-color: #eee;
  color: #5d6064; }

.successbox-verysmall {
  background-color: #77c02b;
  color: #fff; }

.statbox {
  background-color: #eee;
  margin-top: 16px;
  margin-top: 1rem;
  overflow: hidden;
  padding: 32px 16px;
  padding: 2rem 1rem;
  text-align: center; }

.graybox {
  background-color: #eee;
  padding: 16px;
  padding: 1rem; }

.ajaxinfobox {
  opacity: .8;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100000; }

.bg-white {
  background-color: #fff; }

.img-right {
  float: left;
  padding-right: 32px;
  padding-right: 2rem; }

.bg-blue {
  background-color: #006fba; }

.bg-gray {
  background-color: #e8e8e8; }

.background-gray {
  background-color: #eee; }

.color-white {
  color: #fff; }

.color-gray {
  color: #545454; }

.color-text-ligthen {
  color: #999999; }

.color-green {
  color: #77c02b; }

.color-table-head {
  color: #999999; }

.mail-text {
  display: none; }

.badge {
  border: 0;
  border-radius: 2px;
  display: inline-block;
  font-weight: 300;
  font-size: 12px;
  font-size: .75rem;
  line-height: 22px;
  line-height: 1.375rem;
  min-width: 48px;
  min-width: 3rem;
  padding: 0 6px;
  padding: 0 .375rem;
  text-align: center; }
  .badge.new {
    background-color: #00568f;
    color: #fff; }
  .badge.warning {
    background-color: #c0be2b;
    color: #fff; }
  .badge.success {
    background-color: #77c02b;
    color: #fff; }
  .badge.error {
    background-color: #d63032;
    color: #fff; }
  .badge.info {
    background-color: #eeeeee; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

.hidden {
  display: none; }

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }
  .visuallyhidden.focusable:active, .visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto; }

.invisible {
  visibility: hidden; }

.clear {
  clear: both; }

.clearfix {
  *zoom: 1; }
  .clearfix:before, .clearfix:after {
    content: '';
    display: table; }
  .clearfix:after {
    clear: both; }

.mobile-only,
.mobile-phone-only {
  display: none; }

@media screen and (max-width: 1024px) {
  .page-headline {
    font-size: 1.25rem;
    line-height: 1.5rem; }
  .index-headline {
    margin-bottom: 8rem;
    margin-top: 4.5rem;
    max-width: 850px; }
    .index-headline p {
      font-size: 1.25rem;
      line-height: 1.5rem;
      margin-top: 1.5rem; }
  h1 {
    font-size: 1.875rem;
    font-weight: 500;
    line-height: 2.125rem; }
  h2 {
    font-size: 1.625rem;
    font-weight: 500;
    line-height: 1.875rem; }
  h3 {
    font-size: 1.375rem;
    font-weight: 400;
    line-height: 1.625rem; }
  h4 {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem; }
  .mt1 {
    margin-top: 0.75rem; }
  .mt2 {
    margin-top: 1.5rem; }
  .mt3 {
    margin-top: 2.25rem; }
  .mt4 {
    margin-top: 3rem; }
  .mb1 {
    margin-bottom: 0.75rem; }
  .mb2 {
    margin-bottom: 1.5rem; }
  .mb3 {
    margin-bottom: 2.25rem; }
  .mb4 {
    margin-bottom: 3rem; }
  .my1 {
    margin-bottom: 0.75rem;
    margin-top: 0.75rem; }
  .my2 {
    margin-bottom: 1.5rem;
    margin-top: 1.5rem; }
  .my3 {
    margin-bottom: 2.25rem;
    margin-top: 2.25rem; }
  .my4 {
    margin-bottom: 3rem;
    margin-top: 3rem; }
  .font-big {
    font-size: 1.875rem;
    font-weight: 400;
    line-height: 2.125rem; }
  .font-large {
    font-size: 1.625rem;
    font-weight: 400;
    line-height: 1.875rem; }
  .font-medium,
  .infobox,
  .infobox-small {
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.5rem; }
  .font-small {
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1.125rem; }
  .font-verysmall {
    font-size: 0.625rem;
    font-weight: 300;
    line-height: 0.875rem; }
  .main {
    padding-bottom: 1.5rem;
    padding-top: 1.5rem; }
  .blog article {
    margin-top: 3rem; } }

@media screen and (max-width: 768px) {
  .main {
    padding-bottom: 2rem;
    margin-left: 0;
    min-height: inherit;
    padding-top: 1rem; }
  .mobile-only {
    display: inherit; }
  .teaser-without-img {
    padding-top: 3.5rem; }
  .ajaxinfobox {
    text-align: center;
    top: 56px; } }

@media screen and (max-width: 640px) {
  .wrapper,
  .wrapper-inner {
    padding: 0 1.25rem; }
  .page-headline {
    font-size: 1.125rem;
    line-height: 1.375rem; }
  .login-headline {
    font-size: 1.125rem;
    line-height: 1.375rem;
    margin: 3.5rem auto; }
  .index-headline {
    margin-bottom: 4rem;
    margin-top: 1.75rem;
    max-width: 640px; }
    .index-headline p {
      font-size: 1.125rem;
      line-height: 1.375rem;
      margin-top: 1.375rem; }
  h1 {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.75rem; }
  h2 {
    font-size: 1.375rem;
    font-weight: 400;
    line-height: 1.625rem; }
  h3 {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.5rem; }
  h4 {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem; }
  .font-big {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.75rem; }
  .font-large {
    font-size: 1.375rem;
    font-weight: 400;
    line-height: 1.625rem; }
  .font-medium,
  .infobox,
  .infobox-small {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.25rem; }
  .font-very-small {
    font-size: 0.75rem;
    font-weight: 300;
    line-height: 1rem; }
  .mobile-hidden {
    display: none; }
  .mobile-top1 {
    margin-top: 1rem; }
  .mobile-top2 {
    margin-top: 2rem; }
  .mobile-bottom1 {
    margin-bottom: 1rem; }
  .mobile-bottom2 {
    margin-bottom: 2rem; }
  .mobile-pl1 {
    padding-left: 1rem; }
  .pl1-tablet {
    padding-left: 0; }
  .teaser-right {
    padding-right: 0;
    text-align: center; }
  .teaser-left {
    margin-top: 2rem;
    text-align: center; }
  aside {
    padding-left: 0; }
  .mobile-phone-only {
    display: inherit; }
  .mobile-center {
    text-align: center; }
  ul.filter li {
    border-bottom: 0;
    border-left: 1px solid #545454;
    border-right: 1px solid #545454;
    border-top: 1px solid #545454;
    float: none;
    padding: .5rem 1rem; }
    ul.filter li:first-child {
      border-bottom-right-radius: 0;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px; }
    ul.filter li:last-child {
      border-bottom: 1px solid #545454;
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
      border-top-right-radius: 0; }
  aside.sidemenu,
  .sidecontent {
    width: 100%; }
  .sidecontent {
    -o-background-clip: padding-box !important;
       background-clip: padding-box !important;
    border: 0 solid transparent;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    float: left;
    margin-left: 0;
    padding-left: 0; } }

.marker {
  background-color: #acceee; }

.thumbnail, .thumbnail-big {
  margin: 16px;
  margin: 1rem;
  background: #eee;
  text-align: center;
  font-weight: 700; }

.thumbnail {
  padding: 16px;
  padding: 1rem; }

.thumbnail-big {
  padding: 32px;
  padding: 2rem; }

.sidebar {
  padding-left: 16px;
  padding-left: 1rem; }
  .sidebar a {
    display: block; }
    .sidebar a:hover {
      background: #66a8d5; }

.sidebar-box {
  padding: 8px;
  padding: 0.5rem;
  margin-bottom: 16px;
  margin-bottom: 1rem;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 300;
  line-height: 24px;
  line-height: 1.5rem;
  background-color: #e5f0f8; }

@media screen and (max-width: 640px) {
  .thumbnail {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto; }
  .thumbnail-big {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto; }
  .sidebar {
    margin-top: 2rem; } }

.thumbnail:hover {
  background: #bebebe; }

.footer {
  background-color: #404040;
  /*  border-top: solid 2rem $telis-blue; */
  color: #fff; }
  .footer h4 {
    margin-bottom: 6px;
    margin-bottom: .375rem; }
  .footer ul {
    display: inline-block;
    list-style: none;
    margin: 0; }
    .footer ul li {
      float: left;
      padding: 16px;
      padding: 1rem; }
  .footer a {
    color: #fff;
    font-size: 14px;
    font-size: .875rem;
    font-weight: 300;
    line-height: 18px;
    line-height: 1.125rem;
    text-decoration: none; }
    .footer a:hover {
      opacity: .6; }
  .footer p {
    font-weight: 300; }
  .footer .textfield {
    width: 100%; }
  .footer .btn {
    padding: 10px;
    padding: .625rem;
    width: 100%; }
  .footer .logo-left,
  .footer .logo-right {
    line-height: 46px;
    line-height: 2.875rem; }
  .footer .logo-right {
    float: right; }
    .footer .logo-right li {
      float: left;
      font-size: 26px;
      font-size: 1.625rem;
      padding: 0 0 0 24px;
      padding: 0 0 0 1.5rem; }
    .footer .logo-right a {
      display: block;
      height: 40px;
      height: 2.5rem;
      padding-top: 8px;
      padding-top: .5rem;
      text-align: center;
      vertical-align: middle;
      width: 40px;
      width: 2.5rem; }

.footer-left {
  text-align: center; }

.footer-right {
  text-align: center; }

.footer-content {
  margin-bottom: 16px;
  margin-bottom: 1rem;
  margin-top: 16px;
  margin-top: 1rem;
  text-align: center; }
  .footer-content .subline {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 28px;
    line-height: 1.75rem; }

@media screen and (max-width: 640px) {
  .logo-left {
    margin-top: 2rem;
    text-align: center; }
    .logo-left .siegel-telis-block {
      display: inline-block; }
  .footer a {
    font-size: .75rem;
    line-height: 1rem; }
  .footer .abobtn {
    margin-top: 1rem; }
  .footer .btn {
    padding: .625rem 2rem;
    width: auto; }
  .footer .logo-right {
    float: none; }
    .footer .logo-right ul {
      margin-top: 1rem;
      padding: 0; }
    .footer .logo-right li:first-child {
      padding-left: 0; } }

.header {
  background-color: #006fba;
  color: #fff;
  -webkit-transition: all .25s ease-in-out;
  -o-transition: all .25s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: auto;
  z-index: 99999; }

.headerlogo {
  background-image: url("/assets/images/headerlogo-bw.png");
  -o-background-size: 210px 34px;
  background-size: 210px 34px;
  display: block;
  height: 34px;
  position: absolute;
  width: 210px; }
  .headerlogo:hover {
    opacity: 1; }

.navigation {
  position: relative;
  padding-top: 24px;
  padding-top: 1.5rem; }
  .navigation .nav-toggle {
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    font-size: .875rem;
    font-weight: 500;
    line-height: 16px;
    line-height: 1rem;
    padding: 8px 16px;
    padding: .5rem 1rem;
    text-decoration: none;
    -webkit-transition: all .25s ease-in-out;
    -o-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out; }
    .navigation .nav-toggle:hover {
      color: #535353;
      opacity: .6; }
  .navigation ul.nav-top {
    line-height: 32px;
    line-height: 2rem;
    list-style: none;
    margin: 0;
    padding: 0; }
    .navigation ul.nav-top li {
      display: block;
      float: left; }
      .navigation ul.nav-top li span {
        font-weight: 300; }
      .navigation ul.nav-top li img {
        border-radius: 2px;
        height: 32px;
        height: 2rem;
        vertical-align: middle;
        width: 23.04px;
        width: 1.44rem; }
      .navigation ul.nav-top li a, .navigation ul.nav-top li span {
        color: #fff;
        font-size: 13.2px;
        font-size: .825rem;
        padding: 0 10px;
        padding: 0 .625rem; }

.tabs {
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  white-space: nowrap;
  width: 100%; }

.profilebox {
  background-color: #fcf8e3;
  color: #534b00;
  font-size: 14px;
  font-size: 0.875rem;
  left: 0;
  padding: 0 24px;
  padding: 0 1.5rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999999; }

.nav-collapse {
  color: #fff;
  font-size: 14px;
  font-size: .875rem;
  font-weight: 300;
  line-height: 36px;
  line-height: 2.25rem;
  padding-left: 0; }
  .nav-collapse ul {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%; }
  .nav-collapse li {
    display: block;
    float: left; }
    .nav-collapse li:last {
      padding-right: 0; }
  .nav-collapse a {
    color: #fff;
    display: block;
    opacity: 1;
    padding: 0 48px 0 0;
    padding: 0 3rem 0 0;
    text-decoration: none;
    -webkit-transition: all .25s ease-in-out;
    -o-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out; }
    .nav-collapse a:hover {
      opacity: .8;
      -webkit-transition: all .25s ease-in-out;
      -o-transition: all .25s ease-in-out;
      transition: all .25s ease-in-out; }
    .nav-collapse a:hover::after {
      background: #fff none repeat scroll 0 0;
      height: 4px;
      -webkit-transition: all .25s ease-in-out;
      -o-transition: all .25s ease-in-out;
      transition: all .25s ease-in-out; }
    .nav-collapse a::after {
      background: #006fba none repeat scroll 0 0;
      content: '';
      display: block;
      height: 4px;
      opacity: 1;
      -webkit-transition: all .25s ease-in-out;
      -o-transition: all .25s ease-in-out;
      transition: all .25s ease-in-out;
      width: 100%; }
    .nav-collapse a.active::after {
      background: #fff none repeat scroll 0 0; }

.js .nav-collapse {
  position: relative; }
  .js .nav-collapse.closed {
    max-height: none; }

.nav-toggle {
  display: none; }

.header-img {
  color: #fff;
  margin: 32px 48px 0;
  margin: 2rem 3rem 0;
  max-width: 1048px;
  padding: 32px;
  padding: 2rem; }

@media screen and (min-width: 1025px) {
  .navigation {
    height: 82px;
    height: 5.125rem; } }

@media screen and (max-width: 1024px) {
  .navigation {
    height: 56px;
    height: 3.5rem; } }

@media screen and (max-width: 1024px) {
  .headerlogo {
    background-image: url("/assets/images/headerlogo-mobile.png");
    -o-background-size: 148px 24px;
    background-size: 148px 24px;
    height: 24px;
    width: 148px;
    margin: 1rem 1rem .5rem 1rem; }
  .navigation {
    padding: 0; }
    .navigation ul.nav-top {
      line-height: 3.5rem;
      padding-right: .375rem; }
  .tabs {
    float: left;
    margin-top: -1rem;
    padding-top: 0; }
  .nav-collapse {
    font-size: .75rem;
    line-height: 1.5rem;
    padding-left: 1rem; } }

@media screen and (max-width: 768px) {
  .header {
    height: 3.5rem;
    min-height: 3.5rem; }
  .profilebox {
    font-size: 0.75rem;
    padding: 0 1rem; }
  .navigation .nav-menu {
    display: inline-block;
    -webkit-transition: all .9s ease;
    -o-transition: all .9s ease;
    transition: all .9s ease; }
  .navigation ul.nav-top {
    padding: 0; }
    .navigation ul.nav-top a {
      display: block;
      line-height: 3.5rem; }
  .navigation .nav-menu:hover {
    background-color: #e9e9e9;
    color: #535353;
    opacity: .95; }
  .tabs {
    float: inherit; }
  .js .nav-collapse {
    clip: rect(0 0 0 0);
    display: block;
    max-height: 0;
    margin-top: 0;
    overflow: hidden;
    position: absolute; }
  .js .nav-collapse-0.opened {
    clip: auto;
    overflow: auto;
    right: 0; }
  .nav-collapse {
    background-color: #e9e9e9;
    -webkit-box-shadow: 0 2px 2px 2px lightgray;
    box-shadow: 0 2px 2px 2px lightgray;
    float: none;
    line-height: 1rem;
    padding: 0 1rem;
    z-index: 120; }
    .nav-collapse a {
      border-bottom: 1px solid lightgray;
      color: #535353;
      display: block;
      font-size: 1rem;
      padding: .825rem; }
      .nav-collapse a::after {
        height: 0;
        opacity: 0; }
      .nav-collapse a:hover::after {
        height: 0;
        opacity: 0; }
    .nav-collapse li {
      float: none;
      opacity: .95;
      width: 100%; }
      .nav-collapse li.mobile-hidden {
        display: none; }
      .nav-collapse li:last-child a {
        border-bottom: 0; }
  .nav-toggle {
    display: inline;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
    .nav-toggle:before {
      content: '\e5d2';
      display: inline-block;
      font: normal normal normal 20px/1 'Material Icons';
      font-size: inherit;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      text-align: center;
      text-rendering: auto;
      width: 1.28571429em; }
  .js-nav-active .nav-menu {
    background-color: #e9e9e9;
    color: #535353;
    opacity: .95;
    -webkit-transition: none;
    -o-transition: none;
    transition: none; }
  .js-nav-active .nav-toggle {
    color: #535353; }
  .js-nav-active .nav-toggle:before {
    content: '\e5ce'; }
  .header-img {
    margin: 0;
    padding: 5rem 3rem 2rem; } }

@media screen and (max-width: 640px) {
  .header-img {
    padding: 5rem 2rem 2rem; } }

@media only screen and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 2), only screen and (max-width: 1024px) and (-o-min-device-pixel-ratio: 2 / 1), only screen and (max-width: 1024px) and (-o-min-device-pixel-ratio: 2/1), only screen and (max-width: 1024px) and (min-resolution: 2dppx), only screen and (max-width: 1024px) and (min-resolution: 192dpi) {
  .headerlogo {
    background-image: url("/assets/images/headerlogo-mobile@2x.png"); } }

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-resolution: 2dppx), only screen and (min-resolution: 192dpi) {
  .headerlogo {
    background-image: url("/assets/images/headerlogo-bw@2x.png"); } }

@media only screen and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 3), only screen and (max-width: 1024px) and (-o-min-device-pixel-ratio: 3 / 1), only screen and (max-width: 1024px) and (-o-min-device-pixel-ratio: 3/1), only screen and (max-width: 1024px) and (min-resolution: 3dppx), only screen and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 3.64583), only screen and (max-width: 1024px) and (-o-min-device-pixel-ratio: 175 / 48), only screen and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 3.6458333333333335), only screen and (max-width: 1024px) and (-o-min-device-pixel-ratio: 175/48), only screen and (max-width: 1024px) and (min-resolution: 350dpi) {
  .headerlogo {
    background-image: url("/assets/images/headerlogo-mobile@3x.png"); } }

@media only screen and (-webkit-min-device-pixel-ratio: 3), only screen and (-o-min-device-pixel-ratio: 3 / 1), only screen and (-o-min-device-pixel-ratio: 3/1), only screen and (min-resolution: 3dppx), only screen and (-webkit-min-device-pixel-ratio: 3.64583), only screen and (-o-min-device-pixel-ratio: 175 / 48), only screen and (-webkit-min-device-pixel-ratio: 3.6458333333333335), only screen and (-o-min-device-pixel-ratio: 175/48), only screen and (min-resolution: 350dpi) {
  .headerlogo {
    background-image: url("/assets/images/headerlogo-bw@3x.png"); } }

.image,
.image-big {
  width: 100%; }

.image-big {
  max-height: 200px; }

.img-center {
  margin: 0 auto 24px;
  margin: 0 auto 1.5rem; }

.img-left {
  float: left;
  margin-right: 16px;
  margin-right: 1rem; }

.img-link {
  border: 1px solid #e8e8e8;
  -webkit-box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5);
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5); }

.img-border {
  border: 1px solid #e8e8e8; }

.submenu-icon {
  font-size: 16px;
  font-size: 1rem;
  font-weight: bold; }

.app-logo-text {
  background-image: url("/assets/images/app-logo-text.png");
  -o-background-size: 450px 150px;
  background-size: 450px 150px;
  height: 150px;
  width: 450px; }

@media screen and (max-width: 1024px) {
  .app-logo-text {
    -o-background-size: 455px 120px;
    background-size: 455px 120px;
    height: 120px;
    width: 455px; }
  .icons-user {
    -o-background-size: 60px 60px;
    background-size: 60px 60px;
    height: 60px;
    width: 60px; } }

.icon-color-error {
  color: #d63032; }

.icon-color-success {
  color: #77c02b; }

.icon-color-warning {
  color: #c0be2b; }

.icon-color-info {
  color: #999999; }

.material-icons.md-14 {
  font-size: 14px;
  font-size: .875rem;
  vertical-align: baseline; }

.material-icons.md-16 {
  font-size: 16px;
  font-size: 1rem;
  vertical-align: middle; }

.material-icons.md-20 {
  font-size: 20px;
  font-size: 1.25rem;
  vertical-align: middle; }

@media screen and (max-width: 640px) {
  .app-logo-text {
    -o-background-size: 228px 60px;
    background-size: 228px 60px;
    height: 60px;
    width: 228px; } }

.btn {
  background-color: #fff;
  border: medium none;
  border-radius: 2px;
  color: #333;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 1px;
  opacity: .8;
  padding: 16px 32px;
  padding: 1rem 2rem;
  text-align: center;
  text-decoration: none;
  -webkit-transition: background-color, color .3s ease-in-out 0s;
  -o-transition: background-color, color .3s ease-in-out 0s;
  transition: background-color, color .3s ease-in-out 0s;
  vertical-align: middle; }
  .btn.primary {
    color: #333;
    font-size: 20px;
    font-size: 1.25rem;
    font-weight: 400; }
    .btn.primary:hover, .btn.primary:focus {
      background-color: #829dc2; }
  .btn.std {
    color: #333;
    font-weight: 500; }
    .btn.std:hover, .btn.std:focus {
      background-color: #545454;
      color: #fff; }
  .btn.text {
    color: #333;
    font-weight: 500;
    line-height: 28px;
    line-height: 1.75rem; }
    .btn.text:hover, .btn.text:focus {
      background-color: #545454;
      color: #fff; }
  .btn.blue {
    background-color: #006fba;
    color: #fff;
    font-weight: 500; }
    .btn.blue:hover, .btn.blue:focus {
      background-color: #545454; }
  .btn.gray {
    background-color: #545454;
    border: 1px solid #545454;
    color: #fff;
    font-weight: 500; }
    .btn.gray:hover, .btn.gray:focus {
      background-color: #fff;
      color: #545454; }
  .btn.wide {
    line-height: 14px;
    line-height: .875rem;
    padding: 16px 64px;
    padding: 1rem 4rem;
    min-width: 40%; }
  .btn:active {
    opacity: .5; }

@media screen and (max-width: 480px) {
  .btn.wide {
    line-height: .875rem;
    width: 100%;
    padding: 1rem 2rem; } }
