// Button
//
// Die Standard Button.
//
// .primary  - Kennzeichnet einen Primären-Button
// .std  - Standard-Button
// .text  - Text-Button= Standard-Button + line-height: 1.75rem
// .blue  - Bluer-Button
// .gray  - Grauer Button
// .wide  - Breiter Button
//
// Markup:
// <div class="graybox">
//   <a href="#" class="btn {$modifiers}">Link Button</a>
//   <button class="btn {$modifiers}">Button Element</button>
//   <input type="button" class="btn {$modifiers}" value="input[type='button']"/>
// </div>
//
// Styleguide 2.0
.btn {
  background-color: $bg-white;
  border: medium none;
  border-radius: 2px;
  color: $text-color;
  cursor: pointer;
  display: inline-block;
  font-size: .875rem;
  font-style: normal;
  font-weight: $font-weight-regular;
  letter-spacing: 1px;
  opacity: .8;
  padding: 1rem 2rem;
  text-align: center;
  text-decoration: none;
  transition: background-color, color .3s ease-in-out 0s;
  vertical-align: middle;

  &.primary {
    color: $text-color;
    font-size: 1.25rem;
    font-weight: $font-weight-regular;

    &:hover,
    &:focus {
      background-color: $btn-primary-bg-color;
    }
  }

  &.std {
    color: $text-color;
    font-weight: $font-weight-bold;

    &:hover,
    &:focus {
      background-color: $button-gray;
      color: $text-white;
    }
  }

  &.text {
    color: $text-color;
    font-weight: $font-weight-bold;
    line-height: 1.75rem;

    &:hover,
    &:focus {
      background-color: $button-gray;
      color: $text-white;
    }
  }

  &.blue {
    background-color: $telis-blue;
    color: $text-white;
    font-weight: $font-weight-bold;

    &:hover,
    &:focus {
      background-color: $button-gray;
    }
  }

  &.gray {
    background-color: $button-gray;
    border: 1px solid $button-gray;
    color: $text-white;
    font-weight: $font-weight-bold;

    &:hover,
    &:focus {
      background-color: $bg-white;
      color: $button-gray;
    }
  }

  &.wide {
    line-height: .875rem;
    padding: 1rem 4rem;
    min-width: 40%;
  }

  &:active {
    opacity: .5;
  }
}

@media screen and (max-width: 480px) {
  .btn.wide {
    line-height: .875rem;
    width: 100%;
    //max-width: 100%;
    padding: 1rem 2rem;
  }
}
