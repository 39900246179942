.col,
.col-left {
  -o-background-clip: padding-box !important;
  background-clip: padding-box !important;
  border: 0 solid rgba(0, 0, 0, 0);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
}

.row {

  /*.col {
    padding-left: 1rem;
  }*/

  .col:first-child {
    padding-left: 0;
  }

  .span1 {
    width: 16.6666666667%;
  }

  .span2 {
    width: 33.3333333333%;
  }

  .span3, .mobile-span3 {
    width: 50%;
  }

  .span4 {
    width: 66.6666666667%;
  }

  .span5 {
    width: 83.3333333333%;
  }

  .span6 {
    margin-left: 0;
    width: 100%;
  }
    
  .span7 {
    width: 25%;
  }
    
  .span8 {
    width: 75%;
  } 
}

.row-fixed,
%row-fixed {
  @include clearfix();
  @extend .row;
}

@media screen and (max-width: 640px) {
  .mobile-none {
    display: none;
  }

  .row {
    .span1,
    .span2,
    .span3,
    .span4,
    .span5,
    .span6,
    .span7,
    .span8 {
      padding-left: 0;
      width: 100%;
    }
  }
}
