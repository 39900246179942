@mixin clearfix() {
  //ignore scsslint start
  *zoom: 1;
  //ignore scsslint end

  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin headlinetext($size, $line: .5, $weight: 'normal') {
  font-size: $size;
  font-weight: $weight;
  line-height: $size + $line;
}
