html,
%html {
  background-color: $footer-bgcolor;
  border: 0;
  color: $text-color;
  font-family: $base-font-family;
  font-size: $base-font-size;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}

body {
  @extend %html;
  -webkit-font-feature-settings: 'liga';
  -moz-font-feature-settings: 'liga';
  font-feature-settings: 'liga';
  text-rendering: optimizeLegibility;
}

.wrapper,
%wrapper {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 1.5rem;
}

.wrapper-inner,
%wrapper-inner {
  margin: 0 auto;
  max-width: 750px;
  padding: 0 1.5rem;
}

.main {
  background-color: $bg-color;
  min-height: 600px;
  padding-bottom: 2rem;
  padding-top: 2rem;
}

img {
  max-width: 100%;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.page-headline {
  font-size: 1.5rem;
  line-height: 2rem;
}

.login-headline {
  @extend %wrapper-inner;
  @extend %row-fixed;
  color: $header-color;
  margin: 6rem auto 10rem;
  text-align: center;

  p {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-top: 2rem;
  }

  .button {
    margin-top: 2.75rem;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
a {
  border-width: 0;
  margin: 0;
  padding: 0;
  -webkit-transform-origin: left top 0;
  -ms-transform-origin: left top 0;
  -o-transform-origin: left top 0;
  transform-origin: left top 0;
}

h1 {
  @include headlinetext($h1-font-size, .5, 500);
}

h2 {
  @include headlinetext($h2-font-size, .5, 500);
}

h3 {
  @include headlinetext($h3-font-size, .5, 400);
}

h4 {
  @include headlinetext($h4-font-size, .5, 400);
}

.mt05 {
  margin-top: .5rem;
}

.mb05 {
  margin-bottom: .5rem;
}

@for $i from 1 through 4 {
  .mt#{$i} {
    margin-top: 1rem * $i;
  }
}

@for $i from 1 through 4 {
  .mb#{$i} {
    margin-bottom: 1rem * $i;
  }
}

@for $i from 1 through 4 {
  .my#{$i} {
    margin-bottom: 1rem * $i;
    margin-top: 1rem * $i;
  }
}

.mr1 {
  margin-right: 1rem;
}

.mr2 {
  margin-right: 2rem;
}

.pr05 {
  padding-right: 0.5rem;    
}

.pr1 {
  padding-right: 1rem;
}

.pl0 {
  padding-left: 0;
}

.pl05 {
    padding-left: 0.5rem;
}

.pl1 {
  padding-left: 1rem;
}

.pt05 {
    padding-top: 0.5rem;
}

.pt1 {
  padding-top: 1rem;
}

.pb05 {
    padding-bottom: 0.5rem
}

.pb1 {
  padding-bottom: 1rem;
}

.py1 {
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.pl1-tablet {
  padding-left: 1rem;
}

.lh2 {
  line-height: 2rem;
}

.center {
  margin-left: auto;
  margin-right: auto;  
}

.left {
  float: left;
}

.right {
  float: right;
}

.text-center {
  text-align: center;
}

.text-right,
%text-right {
  text-align: right;
}

.text-left,
%text-left {
  text-align: left;
}

.font-big {
  @include headlinetext($h1-font-size, .5, 400);
}

.font-large {
  @include headlinetext($h2-font-size, .5, 400);
}

.font-medium {
  @include headlinetext($medium-font-size, .5, 300);
}

.font-small {
  @include headlinetext(1rem, .25, 300);
}

.font-very-small {
  @include headlinetext(.875rem, .25, 300);
}


aside {

  &.sidemenu {
    float: left;
    width: 20%;
  }

  hr {


    border: 0;
    border-top: 1px solid $table-border-color;
    display: block;
    height: 1px;
    margin: 1em 0;
    padding: 0;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  h4 {
    border-radius: 2px;
    color: $table-th-color;
    padding: 0 1rem;
  }

  a {
    border-radius: 2px;
    display: block;
    padding: .5rem 1rem;
    -o-transition: background-color .25s ease 0s;
    -webkit-transition: background-color .25s ease 0s;
    transition: background-color .25s ease 0s;

    &:hover {
      background-color: $menu-hovered-color;
      opacity: 1;
    }

    &.warning:hover {
      background-color: $warning-bgcolor;
      opacity: 1;
    }
  }
}

.sidecontent {
  float: left;
  padding-left: 1rem;
  width: 80%;
}

.row-fixed-blue {
  @extend %row-fixed;
  background-color: $telis-blue;
  color: $text-white;
}

.teaser,
.pageinntertext {
  @extend %row-fixed;
}

.teaser {
  font-style: italic;
  text-align: left;
}

.light {
  font-weight: $font-weight-light;
}

.regular {
  font-weight: $font-weight-regular;
}

.bold5 {
  font-weight: $font-weight-bold;
}

.bold {
  font-weight: $font-weight-bolder;
}

.text-info {
  font-weight: $font-weight-light;
  opacity: .8;
}

.text-icon {
  font-size: .5rem;
  font-weight: $font-weight-light;
}

.italic {
  font-style: italic;
}

.font-normal {
  font-style: normal;
}

.infotext-medium {
  font-size: 1.25rem;
  font-weight: $font-weight-light;
  line-height: 1.375rem;
}

.infotext {
  font-size: 1.125rem;
  font-weight: $font-weight-light;
  line-height: 1.375rem;
}

.cursor-default {
    cursor:default;
}

.nowrap {
  white-space: nowrap;
}

a {
  color: $text-color;
  text-decoration: none;

  &.text {
    font-weight: $font-weight-bold;
  }

  &:hover {
    opacity: .6;
  }
}

ol {

  padding-left: 1.5rem;

  li {
    padding-top: .5rem;

    &::before {
      font-weight: $font-weight-bold;
    }
  }
}

ul {

  list-style: none;

  &.list-style {
    list-style: disc;
  }

  &.filter {

    display: inline-block;

    li {
      border-bottom: 1px solid $button-gray;
      border-left: 1px solid $button-gray;
      border-top: 1px solid $button-gray;
      cursor: pointer;
      float: left;
      padding: .25rem 1rem;

      &.active,
      &:hover {
        background-color: $bg-gray-filter;
        color: $bg-white;
      }

      &:first-child {
        border-bottom-right-radius: 2px;
        border-top-left-radius: 2px;
      }

      &:last-child {
        border-bottom-right-radius: 2px;
        border-right: 1px solid $button-gray;
        border-top-right-radius: 2px;
      }
    }
  }
}

.width100 {
  width: 100%;
}

.width50 {
  width: 50%;
}

.minus-style {
  list-style-type: '- ';
  margin-left: 0;
  padding-left: 1rem;
}

.errorbox {
  background-color: $error-bgcolor;
  color: $error-color;
  padding: 1rem;
}

.successbox {
  background-color: $success-bgcolor;
  color: $success-color;
  padding: 1rem;
}

.warningbox {
  background-color: $warning-bgcolor;
  color: $warning-color;
  padding: 1rem;
}

.infobox {
  background-color: $gray-bgcolor;
  font-size: $medium-font-size;
  font-style: italic;
  font-weight: $font-weight-light;
  line-height: $medium-font-size + .5;
  padding: 2rem;
}

.infobox-small {
  background-color: $gray-bgcolor;
  font-size: $medium-font-size;
  font-weight: $font-weight-light;
  line-height: $medium-font-size + .5;
  padding: 1rem;
}

.infobox-verysmall,
.successbox-verysmall {
  font-size: .875rem;
  font-weight: $font-weight-light;
  line-height: 1.125rem;
  padding: .25rem 1rem;
}

.infobox-verysmall {
  background-color: $gray-bgcolor;
  color: $execute-color;
}

.successbox-verysmall {
  background-color: $btn-done-bg-color;
  color: $text-white;
}

.statbox {
  background-color: $gray-bgcolor;
  margin-top: 1rem;
  overflow: hidden;
  padding: 2rem 1rem;
  text-align: center;
}

.graybox {
  background-color: $gray-bgcolor;
  padding: 1rem;
}

.ajaxinfobox {
  opacity: .8;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100000;
}

.bg-white {
  background-color: $bg-white;
}

.img-right {
  float: left;
  padding-right: 2rem;
}

.bg-blue {
  background-color: $telis-blue;
}

.bg-gray {
  background-color: $bg-gray;
}

.background-gray {
    background-color: $gray-bgcolor;
}

.color-white {
  color: $text-white;
}

.color-gray {
  color: $button-gray;
}

.color-text-ligthen {
  color: lighten($text-color, 40%);
}

.color-green {
  color: $btn-done-bg-color;
}

.color-table-head {
  color: $table-th-color;
}

.mail-text {
  display: none;
}

.badge {
  border: 0;
  border-radius: 2px;
  display: inline-block;
  font-weight: $font-weight-light;
  font-size: .75rem;
  line-height: 1.375rem;
  min-width: 3rem;
  padding: 0 .375rem;
  text-align: center;

  &.new {
    background-color: $btn-main-bg-color;
    color: $text-white;
  }

  &.warning {
    background-color: $btn-warning-bg-color;
    color: $text-white;
  }

  &.success {
    background-color: $btn-done-bg-color;
    color: $text-white;
  }

  &.error {
    background-color: $btn-error-bg-color;
    color: $text-white;
  }

  &.info {
    background-color: $btn-info-bg-color;
  }
}

// Best practice

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.hidden {
  display: none;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  &.focusable {
    &:active,
    &:focus {
      clip: auto;
      height: auto;
      margin: 0;
      overflow: visible;
      position: static;
      width: auto;
    }
  }
}

.invisible {
  visibility: hidden;
}

// Nicolas Gallagher's micro clearfix

.clear {
  clear: both;
}

.clearfix {
  @include clearfix();
}

.mobile-only,
.mobile-phone-only {
  display: none;
}

@media screen and (max-width: 1024px) {

  .page-headline {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  .index-headline {
    margin-bottom: 8rem;
    margin-top: 4.5rem;
    max-width: 850px;

    p {
      font-size: 1.25rem;
      line-height: 1.5rem;
      margin-top: 1.5rem;
    }
  }

  h1 {
    @include headlinetext($h1-font-size-tablet, .25, 500);
  }

  h2 {
    @include headlinetext($h2-font-size-tablet, .25, 500);
  }

  h3 {
    @include headlinetext($h3-font-size-tablet, .25, 400);
  }

  h4 {
    @include headlinetext($h4-font-size-tablet, .25, 400);
  }

  @for $i from 1 through 4 {
    .mt#{$i} {
      margin-top: 1rem * $i * 3 / 4;
    }
  }
  @for $i from 1 through 4 {
    .mb#{$i} {
      margin-bottom: 1rem * $i * 3 / 4;
    }
  }
  @for $i from 1 through 4 {
    .my#{$i} {
      margin-bottom: 1rem * $i * 3 / 4;
      margin-top: 1rem * $i * 3 / 4;
    }
  }

  .font-big {
    @include headlinetext($h1-font-size-tablet, .25, 400);
  }

  .font-large {
    @include headlinetext($h2-font-size-tablet, .25, 400);
  }

  .font-medium,
  .infobox,
  .infobox-small {
    @include headlinetext($medium-font-size-tablet, .25, 300);
  }

  .font-small {
    @include headlinetext(.875rem, .25, 300);
  }

  .font-verysmall {
    @include headlinetext(.625rem, .25, 300);
  }

  .main {
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
  }

  .blog article {
    margin-top: 3rem;
  }
}

@media screen and (max-width: 768px) {
  .main {
    padding-bottom: 2rem;
    margin-left: 0;
    min-height: inherit;
    padding-top: 1rem;
  }

  .mobile-only {
    display: inherit;
  }

  .teaser-without-img {
    padding-top: 3.5rem;
  }

  .ajaxinfobox {
    text-align: center;
    top: 56px;
  }

}

@media screen and (max-width: 640px) {
  .wrapper,
  .wrapper-inner {
    padding: 0 1.25rem;
  }

  .page-headline {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }

  .login-headline {
    font-size: 1.125rem;
    line-height: 1.375rem;
    margin: 3.5rem auto;
  }

  .index-headline {
    margin-bottom: 4rem;
    margin-top: 1.75rem;
    max-width: 640px;

    p {
      font-size: 1.125rem;
      line-height: 1.375rem;
      margin-top: 1.375rem;
    }
  }

  h1 {
    @include headlinetext($h1-font-size-phone, .25, 400);
  }

  h2 {
    @include headlinetext($h2-font-size-phone, .25, 400);
  }

  h3 {
    @include headlinetext($h3-font-size-phone, .25, 400);
  }

  h4 {
    @include headlinetext($h4-font-size-phone, .25, 400);
  }

  .font-big {
    @include headlinetext($h1-font-size-phone, .25, 400);
  }

  .font-large {
    @include headlinetext($h2-font-size-phone, .25, 400);
  }

  .font-medium,
  .infobox,
  .infobox-small {
    @include headlinetext($medium-font-size-phone, .25, 300);
  }

  .font-very-small {
    @include headlinetext(.75rem, .25, 300);
  }

  .mobile-hidden {
    display: none;
  }

  .mobile-top1 {
    margin-top: 1rem;
  }

  .mobile-top2 {
    margin-top: 2rem;
  }

  .mobile-bottom1 {
    margin-bottom: 1rem;
  }

  .mobile-bottom2 {
    margin-bottom: 2rem;
  }

  .mobile-pl1 {
    padding-left: 1rem;
  }

  .pl1-tablet {
    padding-left: 0;
  }

  .teaser-right {
    padding-right: 0;
    text-align: center;
  }

  .teaser-left {
    margin-top: 2rem;
    text-align: center;
  }

  aside {
    padding-left: 0;
  }

  .mobile-phone-only {
    display: inherit;
  }

  .mobile-center {
    text-align: center;
  }

  ul.filter li {
    border-bottom: 0;
    border-left: 1px solid $button-gray;
    border-right: 1px solid $button-gray;
    border-top: 1px solid $button-gray;
    float: none;
    padding: .5rem 1rem;

    &:first-child {
      border-bottom-right-radius: 0;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
    }

    &:last-child {
      border-bottom: 1px solid $button-gray;
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
      border-top-right-radius: 0;
    }
  }

  aside.sidemenu,
  .sidecontent {
    width: 100%;
  }

  .sidecontent {
    background-clip: padding-box !important;
    border: 0 solid transparent;
    box-sizing: border-box;
    float: left;
    margin-left: 0;
    padding-left: 0;
  }

}

.marker {
  background-color: $table-marker-color;
}

%thumbnail {
  margin: 1rem;    
  background: $gray-bgcolor;
  text-align: center;
  font-weight: $font-weight-bolder;
}

.thumbnail {
  @extend %thumbnail;    
  padding: 1rem;    
}

.thumbnail-big {
  @extend %thumbnail; 
  padding: 2rem;    
}

.sidebar {
    padding-left: 1rem;    
    a {
        display:block;
        &:hover {
            background: $light-telis-blue-hover;
        }
    }
}

.sidebar-box {
    padding: 0.5rem;
    margin-bottom: 1rem;
    @include headlinetext(1rem, .5, 300);
    background-color: $light-telis-blue;
}

@media screen and (max-width: 640px) {
    .thumbnail {
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
    }
    
    .thumbnail-big {
        max-width: 350px;
        margin-left: auto;
        margin-right: auto;
    }
    
    .sidebar {
        margin-top: 2rem;
    }
}

.thumbnail:hover {
    background: $gray-hover-bgcolor;
}
