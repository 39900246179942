// Colors
$telis-blue: #006fba;
$telis-gray: #5c5b60;

// Base Colors
$text-color: #333;
$bg-color: #fff;
$main-blue: #3085d6;
$text-color-lighten: lighten($text-color, 40%);

// Header
$header-color: #fff;

// Nav
$nav-color: $header-color;
$nav-color-active: #85b7e7;
$nav-color-mobile: #535353;
$nav-bgcolor: #e9e9e9;

// Footer
$footer-color: $nav-color;
$footer-bgcolor: #404040;

// Commons
$button-gray: #545454;
$button-red: #c63300;
$text-white: #fff;
$bg-white: #fff;
$bg-gray: #e8e8e8;
$bg-disabled: #ddd;
$bg-gray-filter: #b2b2b2;

// Table
$table-border-color: #d0d0d0;
$table-th-color: lighten($text-color, 40%);
$table-spriped-color: #f2f2f2;
$table-hovered-color: #f2f2f2;
$table-hovered-alt-color: #eaf2fa;
$table-marker-color: #acceee;

// Side menu
$menu-hovered-color: #f2f2f2;

// Form
$checkbox-border-color: #bbb;
$formular-head-active-bgcolor: $telis-blue;
$formular-head-active-color: #fff;
$formular-head-bgcolor: #bdd3e2;

// Vendor
$facebook-bgcolor: #3b5998;
$twitter-bgcolor: #4099ff;
$youtube-bgcolor: #c4302b;
$xing-bgcolor: #126567;

// Box Colors
$error-color: #ba0012;
$error-bgcolor: #f2dede;

$success-color: #325300;
$success-bgcolor: #dff0d8;

$warning-color: #534b00;
$warning-bgcolor: #fcf8e3;

$execute-color: #5d6064;
$execute-bgcolor: #eaf2fa;

$gray-bgcolor: #eee;
$gray-hover-bgcolor: #bebebe;

$light-telis-blue: #e5f0f8;
$light-telis-blue-hover: #66a8d5;

// Buttons
$btn-primary-bg-color: #829dc2;
$btn-main-bg-color: #00568f;
$btn-done-bg-color: #77c02b;
$btn-warning-bg-color: #c0be2b;
$btn-error-bg-color: #d63032;
$btn-info-bg-color: #eeeeee;
