.footer {
  @extend %row-fixed;
  background-color: $footer-bgcolor;
/*  border-top: solid 2rem $telis-blue; */
  color: $footer-color;

  h4 {
    margin-bottom: .375rem;
  }

  ul {
    display: inline-block;
    list-style: none;
    margin: 0;

    li {
      float: left;
      padding: 1rem;
    }
  }

  a {
    color: $nav-color;
    font-size: .875rem;
    font-weight: $font-weight-light;
    line-height: 1.125rem;
    text-decoration: none;

    &:hover {
      opacity: .6;
    }
  }

  p {
    font-weight: $font-weight-light;
  }

  .textfield {
    width: 100%;
  }

  .btn {
    padding: .625rem;
    width: 100%;
  }

  .logo-left,
  .logo-right {
    line-height: 2.875rem;
  }

  .logo-right {
    float: right;

    li {
      float: left;
      font-size: 1.625rem;
      padding: 0 0 0 1.5rem;
    }

    a {
      display: block;
      height: 2.5rem;
      padding-top: .5rem;
      text-align: center;
      vertical-align: middle;
      width: 2.5rem;
    }
  }
}

.footer-left {
  text-align: center;
}

.footer-right {
  text-align: center;
}

.footer-content {
  @extend %wrapper-inner;
  margin-bottom: 1rem;
  margin-top: 1rem;
  text-align: center;

  .subline {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}


// Screen 768
@media screen and (max-width: 640px) {
  .logo-left {
    margin-top: 2rem;
    text-align: center;

    .siegel-telis-block {
      display: inline-block;
    }
  }

  .footer {

    a {
      font-size: .75rem;
      line-height: 1rem;
    }

    .abobtn {
      margin-top: 1rem;
    }

    .btn {
      padding: .625rem 2rem;
      width: auto;
    }

    .logo-right {
      float: none;

      ul {
        margin-top: 1rem;
        padding: 0;
      }

      li:first-child {
        padding-left: 0;
      }
    }
  }
}
