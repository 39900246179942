.header {
  background-color: $telis-blue;
  color: $header-color;
  -webkit-transition: all .25s ease-in-out;
  -o-transition: all .25s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: auto;
  z-index: 99999;
}

.headerlogo {
  background-image: url('/assets/images/headerlogo-bw.png');
  -o-background-size: 210px 34px;
  background-size: 210px 34px;
  display: block;
  height: 34px;
  position: absolute;
  width: 210px;

  &:hover {
    opacity: 1;
  }
}

.navigation {
  @extend %wrapper;
  @extend %row-fixed;
  position: relative;
  padding-top: 1.5rem;

  .nav-toggle {
    color: $nav-color;
    cursor: pointer;
    font-size: .875rem;
    font-weight: $font-weight-bold;
    line-height: 1rem;
    padding: .5rem 1rem;
    text-decoration: none;
    -webkit-transition: all .25s ease-in-out;
    -o-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;

    &:hover {
      color: $nav-color-mobile;
      opacity: .6;
    }
  }

  ul.nav-top {
    line-height: 2rem;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: block;
      float: left;

      span {
        font-weight: 300;
      }

      img {
        border-radius: 2px;
        height: 2rem;
        vertical-align: middle;
        width: 2rem * .72;
      }

      a, span {
        color: $nav-color;
        font-size: .825rem;
        padding: 0 .625rem;
      }
    }
  }
}

.tabs {
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  white-space: nowrap;
  width: 100%;
}

.profilebox {
  background-color: $warning-bgcolor;
  color: $warning-color;
  font-size: 0.875rem;
  left: 0;
  padding: 0 1.5rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999999;
}

.nav-collapse {
  color: $nav-color;
  font-size: .875rem;
  font-weight: $font-weight-light;
  line-height: 2.25rem;
  padding-left: 0;

  ul {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  li {
    display: block;
    float: left;

    &:last {
      padding-right: 0;
    }
  }

  a {
    color: $nav-color;
    display: block;
    opacity: 1;
    padding: 0 3rem 0 0;
    text-decoration: none;
    -webkit-transition: all .25s ease-in-out;
    -o-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;

    &:hover {
      opacity: .8;
      -webkit-transition: all .25s ease-in-out;
      -o-transition: all .25s ease-in-out;
      transition: all .25s ease-in-out;
    }

    &:hover::after {
      background: $bg-white none repeat scroll 0 0;
      height: 4px;
      -webkit-transition: all .25s ease-in-out;
      -o-transition: all .25s ease-in-out;
      transition: all .25s ease-in-out;
    }

    &::after {
      background: $telis-blue none repeat scroll 0 0;
      content: '';
      display: block;
      height: 4px;
      opacity: 1;
      -webkit-transition: all .25s ease-in-out;
      -o-transition: all .25s ease-in-out;
      transition: all .25s ease-in-out;
      width: 100%;
    }

    &.active::after {
      background: $bg-white none repeat scroll 0 0;
    }
  }

  &.opened {
   // max-height: 570px;
  }
}

.js .nav-collapse {
  position: relative;

  &.closed {
    max-height: none;
  }
}

.nav-toggle {
  display: none;
}

.header-img {
  color: $bg-white;
  margin: 2rem 3rem 0;
  max-width: 1048px;
  padding: 2rem;
}

@media screen and (min-width: 1025px) {
    .navigation {
        height: 82px; 
        height: 5.125rem;
    }
}

@media screen and (max-width: 1024px) {
    .navigation {
        height: 56px;
        height: 3.5rem;
    }
}

@media screen and (max-width: 1024px) {

  .headerlogo {
    background-image: url('/assets/images/headerlogo-mobile.png');
    -o-background-size: 148px 24px;
    background-size: 148px 24px;
    height: 24px;
    width: 148px;
    margin: 1rem 1rem .5rem 1rem;
  }

  .navigation {
    padding: 0;

    ul.nav-top {
      line-height: 3.5rem;
      padding-right: .375rem;
    }
  }

  .tabs {
    float: left;
    margin-top: -1rem;
    padding-top: 0;
  }

  .nav-collapse {
    font-size: .75rem;
    line-height: 1.5rem;
    padding-left: 1rem;
  }

}

@media screen and (max-width: 768px) {
  .header {
    height: 3.5rem;
    min-height: 3.5rem;
  }

  .profilebox {
    font-size: 0.75rem;
    padding: 0 1rem;
  }

  .navigation {

    .nav-menu {
      display: inline-block;
      -webkit-transition: all .9s ease;
      -o-transition: all .9s ease;
      transition: all .9s ease;
    }

    ul.nav-top {
      padding: 0;

      a {
      	display: block;
      	line-height: 3.5rem;
      }
    }

    .nav-menu {
      &:hover {
        background-color: $nav-bgcolor;
        color: $nav-color-mobile;
        opacity: .95;
      }
    }

  }

  .tabs {
    float: inherit;
  }

  .js .nav-collapse {
    clip: rect(0 0 0 0);
    display: block;
    max-height: 0;
    margin-top: 0;
    overflow: hidden;
    position: absolute;
  }

  .js .nav-collapse-0.opened {
    clip: auto;
    overflow: auto;
    right: 0;
  }

  .nav-collapse {
    background-color: $nav-bgcolor;
    -webkit-box-shadow: 0 2px 2px 2px lightgray;
    box-shadow: 0 2px 2px 2px lighten($nav-color-mobile, 50%);
    float: none;
    line-height: 1rem;
    padding: 0 1rem;
    z-index: 120;

    a {
      border-bottom: 1px solid lighten($nav-color-mobile, 50%);
      color: $nav-color-mobile;
      display: block;
      font-size: 1rem;
      padding: .825rem;

      &::after {
        height: 0;
        opacity: 0;
      }

      &:hover::after {
        height: 0;
        opacity: 0;
      }
    }

    li {
      float: none;
      opacity: .95;
      width: 100%;

      &.mobile-hidden {
        display: none;
      }

      &:last-child a {
        border-bottom: 0;
      }
    }

  }

  .nav-toggle {
    display: inline;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:before {
      content: '\e5d2';
      display: inline-block;
      font: normal normal normal 20px/1 'Material Icons';
      font-size: inherit;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      text-align: center;
      text-rendering: auto;
      width: 1.28571429em;
    }
  }

  .js-nav-active .nav-menu {
    background-color: $nav-bgcolor;
    color: $nav-color-mobile;
    opacity: .95;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }

  .js-nav-active .nav-toggle {
    color: $nav-color-mobile;
  }

  .js-nav-active .nav-toggle:before {
    content: '\e5ce';
  }

  .header-img {
    margin: 0;
    padding: 5rem 3rem 2rem;
  }

}

@media screen and (max-width: 640px) {
  .header-img {
    padding: 5rem 2rem 2rem;
  }

}


// for high resolution display

@media only screen and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 2),
only screen and (max-width: 1024px) and (-o-min-device-pixel-ratio: 2/1),
only screen and (max-width: 1024px) and (min-resolution: 2dppx),
only screen and (max-width: 1024px) and (min-resolution: 192dpi) {

  .headerlogo {
    background-image: url('/assets/images/headerlogo-mobile@2x.png');
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-resolution: 2dppx),
  only screen and (min-resolution: 192dpi) {

  .headerlogo {
    background-image: url('/assets/images/headerlogo-bw@2x.png');
  }
}

@media only screen and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 3),
  only screen and (max-width: 1024px) and (-o-min-device-pixel-ratio: 3/1),
  only screen and (max-width: 1024px) and (min-resolution: 3dppx),
  only screen and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 3.6458333333333335),
  only screen and (max-width: 1024px) and (-o-min-device-pixel-ratio: 175/48),
  only screen and (max-width: 1024px) and (min-resolution: 350dpi) {

  .headerlogo {
    background-image: url('/assets/images/headerlogo-mobile@3x.png');
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (min-resolution: 3dppx),
  only screen and (-webkit-min-device-pixel-ratio: 3.6458333333333335),
  only screen and (-o-min-device-pixel-ratio: 175/48),
  only screen and (min-resolution: 350dpi) {

  .headerlogo {
    background-image: url('/assets/images/headerlogo-bw@3x.png');
  }
}
